<template>
  <div>
    <v-row>
      <div class="padding-postponed-actions">
        <lead-postpone-action
          :postponed="formatDate(lead.postponed?.date_at)"
          :leadId="lead._id"
          :lead="lead"
          :page="page"
          @updateDate="date => (lead.postponed.date_at = date)"
        />
      </div>
      <div class="px-4" v-if="watchAndEdit">
        <v-menu left bottom>
          <template #activator="{ on: menu }">
            <v-tooltip bottom>
              <template #activator="{ on: tooltipOn }">
                <v-btn
                  icon
                  aria-label="Acciones"
                  v-on="{ ...tooltipOn, ...menu }"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span>Opciones</span>
            </v-tooltip>
          </template>
          <v-list>
            <modal-text-confirmation
              v-if="leadPhase !== 'discarded'"
              @accepted="onModalAccepted"
              size="400"
              name="Descartar"
              title="Descartar lead"
              :text="'¿Desea descartar el lead ' + lead.contact_lead_name + '?'"
              label="Motivo de descarte"
              :textRule="[v => !!v || 'Campo obligatorio']"
            />
            <!-- <v-list-item
              v-if="leadPhase !== 'discarded'"
              @click="discardLeadConfirmation"
            >
              <v-list-item-title>
                Descartar
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item v-else @click="reactivateLead">
              <v-list-item-title color="primary">
                Reactivar
              </v-list-item-title>
            </v-list-item>
            <lead-transform-external-broker :lead="lead" />
          </v-list>
        </v-menu>
      </div>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es");

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    LeadPostponeAction: () => import("@/components/lead/LeadPostpone.vue"),
    LeadTransformExternalBroker: () =>
      import("@/components/lead/LeadTransformExternalBroker.vue"),
    ModalTextConfirmation: () =>
      import("@/components/utils/ModalTextConfirmation.vue")
    // LeadReactivateAction: () => import("@/components/lead/LeadReactivate.vue"),
  },
  props: {
    page: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      role: localStorage.getItem("user_role")
    };
  },
  computed: {
    ...mapState({
      lead: state => state.leads.actualItem,
      preferencesSt: state => state.preferences
    }),
    leadPhase: {
      get() {
        if (this.lead && this.lead.phase) return this.lead.phase;
        return "";
      }
    },
    watchAndEdit() {
      if (this.role == "admin") return true;
      if (this.role == "broker") return true;
      return false;
    }
  },
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      fetchActualLead: "leads/fetchActualLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading",
      setUpdateActualLead: "leads/updateActualLead"
    }),
    formatDate(date) {
      return moment(date).format();
    },
    discardLeadConfirmation() {
      try {
        const self = this;
        // cerrar el modal por conflictos con el input en vue-snotify prompt
        document.getElementById("close-lead-modal").click();
        this.$snotify.prompt(
          `¿Desea descartar el Lead ${self.lead.contact_lead_name}?`,
          "¿Descartar lead?",
          {
            customClass: "snotifyToast__input--filled",
            buttons: [
              {
                text: "Descartar",
                action: toast => this.confirmDiscardLead(toast)
              },
              {
                text: "Cancelar",
                action: toast => {
                  this.$snotify.remove(toast.id);
                }
              }
            ],
            placeholder: "Motivo del descarte", // Max-length = 40,
            maxOnScreen: 1
          }
        );
        setTimeout(() => {
          const snotifyPromptInputTextContainer = document.getElementsByClassName(
            "snotifyToast__input"
          );
          if (
            snotifyPromptInputTextContainer &&
            snotifyPromptInputTextContainer.length > 0
          )
            snotifyPromptInputTextContainer[0].classList.add(
              "snotifyToast__input--filled"
            );
          document
            .getElementsByClassName("snotifyToast__input__field")[0]
            .focus();
        }, 200);
      } catch (error) {
        this.$snotify.error(
          "Ha ocurrido un error. Inténtelo más tarde",
          "Ocurrió un problema.",
          {
            closeOnClick: true,
            maxOnScreen: 1
          }
        );
      }
    },
    onModalAccepted(value) {
      let updateData = {
        leadId: this.lead._id,
        lead: {
          phase: "discarded",
          discard_observations: value
        }
      };
      this.setUpdateActualLead({
        page:
          this.preferencesSt && this.preferencesSt.showKanbanFirst
            ? "kanban"
            : "list",
        leadId: this.lead._id,
        phase: "discarded",
        value_phase: "discarded",
        discard_observations: value,
        operation_phase: "",
        tracking_phase: ""
      });
      this.sendUpdateLead(updateData, false, "Lead descartado");

      return false;
    },
    confirmDiscardLead(toast) {
      const discardMessage = toast.value;
      if (!toast.value.match("snotify")) {
        toast.valid = false;

        let updateData = {
          leadId: this.lead._id,
          lead: {
            phase: "discarded",
            discard_observations: discardMessage
          }
        };
        // cerrar prompts
        this.$snotify.remove(toast.id);
        // mandar a editar lead
        this.setUpdateActualLead({
          page:
            this.preferencesSt && this.preferencesSt.showKanbanFirst
              ? "kanban"
              : "list",
          leadId: this.lead._id,
          phase: "discarded",
          value_phase: "discarded",
          operation_phase: "",
          tracking_phase: ""
        });
        this.sendUpdateLead(updateData, false, "Lead descartado");

        return false;
      } else {
        toast.valid = true; // default value
        this.$snotify.remove(toast.id);
      }
    },
    reactivateLead() {
      let updateData = {
        leadId: this.lead._id,
        lead: {
          phase: "active",
          tracking_phase: "tracking",
          operation_phase: "",
          postponed_date: moment()
        }
      };
      // si se descartó el lead sin asignar, moverlo a Por Asignar
      if (
        !(this.lead.internal_broker && this.lead.internal_broker._id) &&
        !(this.lead.internal_admin && this.lead.internal_admin._id)
      ) {
        updateData.lead.tracking_phase = "unassigned";
      }
      this.sendUpdateLead(updateData, true, "Lead reactivado");
    },
    sendUpdateLead(updateData, isFinallyFetchLead = false, message) {
      const self = this;
      return this.$snotify.async(
        "Actualizando lead",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              self
                .fetchUpdatelLead(updateData)
                .then(() => {
                  resolve({
                    title: message,
                    body: "El lead se ha editado correctamente.",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(() => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema al actualizar el lead.`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  // si definio actualizar el lead actual
                  self.setLoading(false);
                  if (isFinallyFetchLead) self.fetchActualLead(self.lead._id);
                });
            }, 500);
          })
      );
    }
  }
};
</script>
<style scoped>
.padding-postponed-actions {
  padding-top: 5px !important;
}
</style>
